import * as _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { FeatureFlags } from 'common/feature_flags';
import AccessibleBrowseFiltersWrapper from './components/AccessibleBrowseFiltersWrapper';
import { ForgeDrawer } from '@tylertech/forge-react';

if (FeatureFlags.value('accessible_browse_filters')) {
  ReactDOM.render(
    <div className="filters-pane">
      <ForgeDrawer>
        <AccessibleBrowseFiltersWrapper isMobile={false} />
      </ForgeDrawer>
    </div>,
    document.querySelector('#accessible-browse-filters')
  );
  ReactDOM.render(
    <div className="mobile-filters-pane">
      <AccessibleBrowseFiltersWrapper isMobile={true} />
    </div>,
    document.querySelector('#mobile-accessible-browse-filters')
  );
}
